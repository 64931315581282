import styled, { css } from 'styled-components';
import { FONT_SIZE_DOCS_COPY } from 'helper/constants/styles';
import { smallTablet, largeTablet } from 'helper/constants/mediaRules';

const FormLabel = styled.label`
  width: auto;
  font-size: ${FONT_SIZE_DOCS_COPY};
  align-self: flex-start;

  ${smallTablet(css`
    width: 40%;
  `)}

  ${largeTablet(css`
    width: 30%;
  `)}
`;

export default FormLabel;
