import React from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { desktop } from 'helper/constants/mediaRules';
import { zIndexes } from 'helper/constants/styles';

import ContactTitleText from 'src/modules/ContactPageTitle';
import ContactForm from 'src/modules/ContactForm';

import ContactPageList from 'src/components/ContactPageList/ContactPageList';

import { Col, Container, Row } from 'components/Layout/Grid';
import contactLeftSquare from 'S3/images/contact_left_square.svg';

import SEO from '../components/Seo';


const StyledLeftSquare = styled.img`
  width: 43rem;
  position: absolute;
  left: -22rem;
  top: 48rem;
  z-index: ${zIndexes.backgroundShapes};
`;

export const StyledRightSquare = styled(Img)`
  width: 17.5rem;
  position: absolute;
  right: 0;
  top: 38rem;
  z-index: ${zIndexes.backgroundShapes};
`;

const ContactRow = styled(Row)`
  padding: 2rem 0;
  margin: 0;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  ${desktop(css`
    flex-wrap: nowrap;
  `)}
`;

const ContactPage = () => {
  const images = useStaticQuery(graphql`
    fragment contactFluid on File {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query ContactSalesImageQuery {
      contactRightSquare: file(relativePath: { eq: "images/contact_right_square.png" }) {...contactFluid}
    }
  `);

  const { contactRightSquare } = images;

  return (
    <Container style={{ flexDirection: 'column' }}>
      <SEO title="Contact" />
      <ContactTitleText />
      <ContactRow>
        <StyledRightSquare style={{ position: 'absolute' }} id="bg-square-right" fluid={contactRightSquare.childImageSharp.fluid} alt="" />
        <Col px={0} width={[1, 1, 0.7, 0.55]}>
          <ContactForm />
        </Col>
        <Col width={[1, 1, 1, 0.36]}>
          <ContactPageList />
        </Col>
      </ContactRow>
      <StyledLeftSquare id="bg-square-left" src={contactLeftSquare} alt="" />
    </Container>
  );
};

export default ContactPage;
