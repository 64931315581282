import React from 'react';
import styled, { css } from 'styled-components';

import {
  COLOR_FORM_FIELD_BG,
  FONT_SIZE_DOCS_H2,
  FONT_SIZE_ROOT,
  FORM_ERROR_RED,
  zIndexes,
} from 'helper/constants/styles';
import { smallTablet } from 'helper/constants/mediaRules';

import { Container } from 'components/Layout/Grid';
import ErrorIcon from 'images/error_icon.svg';


const ErrorMessageBox = styled.div`
  box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.23);
  z-index: ${zIndexes.navBar};
  background-color: ${COLOR_FORM_FIELD_BG};
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${FORM_ERROR_RED};
  animation: .5s errorHeight;
  
  @keyframes errorHeight {
    0% {
      transform: translateY(100%);
    } 100% {
      transform: translateY(0%);
    }
  }
`;

const ErrorContainer = styled(Container)`
  align-items: center;
  padding: 1.48rem 18px;

  > h3 {
    margin-left: 1rem;
    margin-bottom: 0;
    font-size: ${FONT_SIZE_ROOT};
    padding-top: 4px;

    /* Error strings return lowercase */
    &::first-letter {
      text-transform: capitalize;
    }

    ${smallTablet(css`
      margin-left: 2.2rem;
      padding-top: 0;
      font-size: ${FONT_SIZE_DOCS_H2};
    `)}
  }
`;

const ErrorClose = styled.div`
  margin: 0 0 0 auto;
  cursor: pointer;
  align-self: center;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;

  &::before,
  &::after {
    background: ${FORM_ERROR_RED};
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

/**
 * Reusuable full screen width ErrorBanner function, fixed to screen bottom
 * @param {string} errorString The string to display in the error banner
 * @param {function} closeBanner The function used to reset the banner display
 */
const ErrorBanner = props => (
  <ErrorMessageBox id="qa-contact-error-message">
    <ErrorContainer>
      <img src={ErrorIcon} alt="Error Icon" />
      <h3>{props.errorString}</h3>
      <ErrorClose id="qa-error-close" onClick={props.closeBanner} />
    </ErrorContainer>
  </ErrorMessageBox>
);

export default ErrorBanner;
