import styled, { css } from 'styled-components';

import { smallTablet } from 'helper/constants/mediaRules';

const FormCheckbox = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;

  ${smallTablet(css`
    margin-bottom: 0;
  `)}

  > input {
    transform: scale(1.2);
  }

  > label {
    margin: 0 0 1rem 0.8rem;
    width: 100%;
  }
`;

export default FormCheckbox;
