import Select from 'react-select';
import styled, { css } from 'styled-components';

import {
  COLOR_FORM_FIELD_BG,
  COLOR_FORM_PLACEHOLDER,
  FONT_SIZE_DOCS_COPY,
  JAVA_GREEN,
} from 'helper/constants/styles';
import { smallTablet } from 'helper/constants/mediaRules';

const FormIndustrySelect = styled(Select)`
  background-color: ${COLOR_FORM_FIELD_BG};
  border: transparent 1px solid;
  border-radius: 10px;
  color: #fff;
  width: 100%;
  min-height: 2.625rem;
  margin: 0.4rem 0 1.4rem 0;
  font-size: 1em;

  ${smallTablet(css`
    width: 71.8%;
    margin: 0;
  `)}

  .select__control,
  .select__menu {
    background-color: ${COLOR_FORM_FIELD_BG};
    border: transparent 1px solid;
    border-radius: 10px;
  }

  .select__group {
    border-bottom: 1px solid #aeaeae;
  }

  .select__group:last-of-type {
    border-bottom: none;
  }

  .select__group-heading {
    font-weight: 700;
    color: #fff;
  }

  .select__control--is-focused {
    border: transparent 1px solid;
    box-shadow: none;
  }

  .select__control--is-focused:hover {
    border: transparent 1px solid;
    box-shadow: none;
  }

  .select__dropdown-indicator {
    color: ${COLOR_FORM_PLACEHOLDER};
    transition: transform 0.4s;
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }

  .select__control--menu-is-open {
    border: ${JAVA_GREEN} 1px solid;
    box-shadow: none;

    .select__dropdown-indicator {
      color: ${JAVA_GREEN};

      transition: transform 0.4s;
      transform-origin: 50% 50%;
      transform: rotate(180deg);

      &:hover {
        color: ${JAVA_GREEN};
      }
    }
  }

  .select__control--menu-is-open:hover {
    border: ${JAVA_GREEN} 1px solid;
    box-shadow: none;
  }

  .select__value-container,
  .select__value-container--is-multi {
    padding-left: 1rem;
    font-weight: 400;
  }

  .select__placeholder {
    color: ${COLOR_FORM_PLACEHOLDER};
    font-size: ${FONT_SIZE_DOCS_COPY};
    font-weight: 400;
    width: 100%;
  }

  .select__indicator-separator {
    display: none;
    visibility: hidden;
  }

  .select__option {
    font-weight: 400;
    color: #E8E8E8;
    background-color: #ffffff00;
    padding-left: 1rem;

    &:hover {
      color: ${JAVA_GREEN};
      background-color: #172939;
    }
  }
`;

export default FormIndustrySelect;
