import React from 'react';
import styled, { css } from 'styled-components';

import { smallTablet } from 'helper/constants/mediaRules';
import { FONT_SIZE_36, FONT_SIZE_LANDING_HEADER_BODY, FONT_SIZE_DOCS_LINKS_TOP_LEVEL, MONA_LISA_PINK } from 'helper/constants/styles';
import strings from 'helper/localization/localization.js';

const ContactTextTitle = styled.h1`
  letter-spacing: 1px;
  font-size: ${FONT_SIZE_LANDING_HEADER_BODY};
  color: ${MONA_LISA_PINK};
  margin: 0;
  padding-top: 2rem;

  ${smallTablet(css`
    font-size: ${FONT_SIZE_36};
    max-width: 38rem;
    padding-top: 4rem;
  `)}
`;

const ContactTextSubTitle = styled.p`
  font-size: ${FONT_SIZE_DOCS_LINKS_TOP_LEVEL};
  margin: 0.8rem 0 0 0;
  white-space: pre-line;

  ${smallTablet(css`
    font-size: ${FONT_SIZE_LANDING_HEADER_BODY};
    max-width: 38rem;
  `)}
`;

const ContactPageTitle = () => (
  <>
    <ContactTextTitle id="qa-contact-form-page">{strings.contactSales}</ContactTextTitle>
    <ContactTextSubTitle>
      {`${strings.toRequestAccess}`}
    </ContactTextSubTitle>
  </>
);

export default ContactPageTitle;
