import styled, { css } from 'styled-components';

import { smallTablet } from 'helper/constants/mediaRules';
import {
  MONA_LISA_PINK,
  COLOR_CTA_DISABLED_BG,
  COLOR_CTA_TEXT,
  COLOR_CTA_HOVER_BG,
  FONT_SIZE_DOCS_COPY,
} from 'helper/constants/styles';

const FormSubmitButton = styled.button`
  margin: 0 auto 1rem;
  width: 100%;
  background-color: ${MONA_LISA_PINK};
  color: ${COLOR_CTA_TEXT};
  align-self: flex-end;
  height: 3.4rem;
  font-family: inherit;
  font-weight: 700;
  font-size: ${FONT_SIZE_DOCS_COPY};
  border-radius: 7px;
  border: none;
  cursor: pointer;

  ${smallTablet(css`
    width: 9.8rem;
    height: 2.8rem;
    margin: 0;
  `)}


  &:disabled {
    background-color: ${COLOR_CTA_DISABLED_BG};
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: ${COLOR_CTA_HOVER_BG};
  }
`;

export default FormSubmitButton;
