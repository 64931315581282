import React from 'react';

import strings from 'helper/localization/localization.js';

import FormFieldContainer from 'components/ContactForm/FormFieldContainer';
import FormFieldLabel from 'components/ContactForm/FormFieldLabel';
import FormFieldElement from 'components/ContactForm/FormFieldElement';

const formRowData = [
  {
    label: 'yourName',
    id: 'name',
    placeholder: 'firstLast',
  },
  {
    label: 'yourCompany',
    id: 'company',
    placeholder: 'companyPlaceholder',
  },
  {
    label: 'yourRole',
    id: 'role',
    placeholder: 'rolePlaceholder',
  },
  {
    label: 'yourEmail',
    id: 'email',
    placeholder: 'emailPlaceholder',
  },
  {
    label: 'yourNumber',
    id: 'phone',
    placeholder: '',
    component: 'phoneComponent',
  },
  {
    label: 'yourIndustry',
    id: 'industry',
    placeholder: '',
    component: 'selectComponent',
  },
  {
    label: 'yourInquiry',
    id: 'inquiry',
    placeholder: 'textBoxPlaceholder',
    component: 'textarea',
  },
];

const FormInputRows = props => formRowData.map(input => (
  <FormFieldContainer key={input.id}>
    <FormFieldLabel htmlFor={input.id}>
      {strings[input.label]}
    </FormFieldLabel>
    <FormFieldElement
      component={input.component ? props[input.component] : undefined}
      error={input.id in props.error && !!props.touched[input.id] ? props.error[input.id] : null}
      type={input.type || input.id}
      id={input.id}
      disabled={props.isSubmitting}
      name={input.id}
      placeholder={strings[input.placeholder]}
    />
  </FormFieldContainer>
));

export default FormInputRows;
