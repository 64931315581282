/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';

const Spin = styled.div`
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  > div {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    background-color: #061b2c;

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  > .bounce1 {
    animation-delay: -0.16s;
  }

  > .bounce2 {
    animation-delay: -0.32s;
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      transform: scale(0);
    } 40% {
      transform: scale(1.0);
    }
  }
`;

export const Loading = () => (
  <Spin>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </Spin>
);
