import styled, { css } from 'styled-components';
import { smallTablet } from 'helper/constants/mediaRules';

const FormFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${smallTablet(css`
    flex-direction: row;
    margin-bottom: 1.3rem;
  `)}
`;

export default FormFieldContainer;
