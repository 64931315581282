import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { Formik, Field } from 'formik';
import 'react-phone-number-input/style.css';
import { boolean, object, string } from 'yup';

import { submitContactForm } from 'api/api.js';
import {
  mimiPPLink,
  mimiToULink,
  selectOptionGroups,
  UNAUTH_ROUTES,
} from 'helper/constants/constants';
import { smallTablet } from 'helper/constants/mediaRules';
import {
  JAVA_GREEN,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization.js';

import StyledFormBox from 'components/ContactForm/FormBox';
import FormCheckbox from 'components/ContactForm/FormCheckbox';
import FormContainer from 'components/ContactForm/FormContainer';
import FormInputRows from 'components/ContactForm/FormInputRows';
import FormPhoneInput from 'components/ContactForm/FormPhoneInput';
import FormIndustrySelect from 'components/ContactForm/FormIndustrySelect';
import FormSubmitButton from 'components/ContactForm/FormSubmitButton';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import { Col } from 'components/Layout/Grid';
import { Loading } from 'components/Loading/Loading';

const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0;

  ${smallTablet(css`
    margin-top: 2.4rem;
    flex-direction: row;
  `)}
`;

const TermsLink = styled.a`
  color: ${JAVA_GREEN};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Third party phone `number` input for country auto formatting and custom styling
const Phone = ({ field, form, ...props }) => (
  <FormPhoneInput
    value=""
    placeholder={strings.phonePlaceholder}
    className={'phoneInputContainer'}
    inputClassName={'styledPhoneInput'}
    disabled={props.disabled}
    onChange={(value) => {
      if (!form.touched[field.name]) {
        form.setFieldTouched(field.name);
      }
      form.setFieldValue(field.name, value);
    }}
  />
);

const selectGroupsLocales = () => {
  const optionLocales = [];

  selectOptionGroups.map(group => (
    optionLocales.push({
      label: strings[group.label],
      options: group.options.map(option => ({
        value: option.value,
        label: strings[option.label],
      })),
    })
  ));
  return optionLocales;
};

// Third party `select` input for multi selection and custom styling
const SelectBox = ({ field, form, ...props }) => (
  <FormIndustrySelect
    id="industry"
    name="industry"
    placeholder={strings.industryPlaceholder}
    isMulti
    closeMenuOnSelect={false}
    options={selectGroupsLocales()}
    disabled={props.disabled}
    classNamePrefix="select"
    onChange={(newValue) => {
      if (!form.touched[field.name]) {
        form.setFieldTouched(field.name);
      }
      form.setFieldValue(field.name, newValue);
    }}
  />
);

// Form Validation object using 'Yup'
const contactFormSchema = object().shape({
  name: string()
    .required('Required')
    .min(1)
    .max(1000),
  company: string()
    .required('Required')
    .min(1)
    .max(1000),
  role: string()
    .required('Required')
    .min(1)
    .max(1000),
  email: string()
    .email('Invalid email')
    .required('Required')
    .min(1)
    .max(1000),
  inquiry: string()
    .required('Required')
    .min(1)
    .max(5000),
  privacyTermsAgreed: boolean().oneOf([true], 'Must Accept Privacy Policy and Terms and Conditions'),
});

// Actual `Contact Form` class, making use of 'Formik'
export default class ContactForm extends Component {
  state = {
    formErrorString: '',
    formSubmitSuccess: null,
    formSubmitError: null,
  };

  resetError = () => {
    this.setState({
      formSubmitError: null,
    });
  }

  // Submit Function: to turn Industry selection array into one string, update values object,
  // call submitContactform API call
  formSubmission = async (values, { setSubmitting }) => {
    const formValues = {
      ...values,
      customAdminEmail: values.customAdminEmail ? values.customAdminEmail : undefined,
    };

    if (values.industry.length) {
      formValues.industry = values.industry.map(input => input.value).join(' / ');
    }

    try {
      await submitContactForm(formValues);
      this.setState({ formSubmitSuccess: true });
      navigate(UNAUTH_ROUTES.contactSuccess);
    } catch (err) {
      let errResponse;
      if (err.res !== undefined && err.res[0] !== undefined) {
        // Contact form field validation errors return as Array of objects, caught here
        errResponse = `${err.status}: ${err.res[0].field} "${err.res[0].value}" ${err.res[0].error}`;
      } else if (err.status !== undefined) {
        // If err.res is undefined or has no first field error value, check for err.status value
        errResponse = `${err.status}: ${err.statusText}`;
      } else {
        // If no err.status then there has been no connection made to the backend
        errResponse = `${strings.networkConnectionError}: ${err.message}`;
      }
      setTimeout(() => {
        setSubmitting(false);
        this.setState({
          formSubmitError: true,
          formErrorString: errResponse,
        });
      }, 1500);
    }
  };

  render() {
    return (
      <>
        <FormContainer>
          <Formik
            initialValues={{
              name: '',
              company: '',
              role: '',
              email: '',
              phone: '',
              industry: '',
              inquiry: '',
              privacyTermsAgreed: false,
            }}
            validationSchema={contactFormSchema}
            onSubmit={(values, { setSubmitting }) => {
              this.formSubmission(values, { setSubmitting });
            }}
          >
            {({ errors, isSubmitting, isValid, touched }) => (
              <StyledFormBox>
                <FormInputRows
                  isSubmitting={isSubmitting}
                  touched={touched}
                  error={errors}
                  phoneComponent={Phone}
                  selectComponent={SelectBox}
                  textarea={'textarea'}
                />
                <FormActions>
                  <Col width={[0.9, 0.65, 0.65, 0.65]} style={{ paddingLeft: 0 }}>
                    <FormCheckbox>
                      <Field type="checkbox" id="checkbox" name="privacyTermsAgreed" />
                      <label htmlFor="checkbox">
                        {strings.touAndPPSubmit}
                        {' '}
                        <TermsLink id="qa-contact-ToUlink" rel="noopener noreferrer" href={mimiToULink} target="_blank">
                          {strings.termsOfUse}
                        </TermsLink>
                        {' / '}
                        <TermsLink id="qa-contact-PPlink" rel="noopener noreferrer" href={mimiPPLink} target="_blank">
                          {strings.privacyPolicy}
                        </TermsLink>
                      </label>
                    </FormCheckbox>
                  </Col>
                  <Col>
                    <FormSubmitButton
                      id="qa-contact-submit"
                      type="submit"
                      disabled={isSubmitting || !isValid}
                    >
                      {!isSubmitting && strings.submitForm}
                      {isSubmitting && (
                        <Loading />
                      )}
                    </FormSubmitButton>
                  </Col>
                </FormActions>
                {
                  this.state.formSubmitError === true &&
                  <ErrorBanner
                    errorString={this.state.formErrorString}
                    closeBanner={this.resetError}
                  />
                }
              </StyledFormBox>
            )}
          </Formik>
        </FormContainer>
      </>
    );
  }
}
