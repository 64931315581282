import styled, { css } from 'styled-components';
import { MEDIUM_STONE } from 'helper/constants/styles';
import { smallTablet } from 'helper/constants/mediaRules';

const ContactFormContainer = styled.div`
  background-color: ${MEDIUM_STONE};
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem 2rem;
  width: 100%;
  max-width: 36.6rem;

  ${smallTablet(css`
    padding: 3.5rem 1.8rem;;
  `)}
`;

export default ContactFormContainer;
