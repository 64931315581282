import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

import {
  COLOR_FORM_FIELD_BG,
  COLOR_FORM_PLACEHOLDER,
  DOVE_GRAY,
  FONT_SIZE_DOCS_COPY,
  JAVA_GREEN,
} from 'helper/constants/styles';


const FormPhoneInput = styled(PhoneInput)`
  width: 71.8%;
  background-color: ${COLOR_FORM_FIELD_BG};
  color: #fff;
  border: transparent 1px solid;
  border-radius: 10px;
  height: 2.625rem;
  margin: 0;
  font-weight: 400;
  display: flex;
  padding-left: 1rem;
  font-size: 1em;

  &:focus-within {
    border: ${JAVA_GREEN} 1px solid !important;
  }

  @media only screen and (max-width: 32em) {
    width: 100%;
    margin: 0.4rem 0 1.4rem 0;
  }

  > .react-phone-number-input__row {
    width: 100%;
  }

  > input,
  .styledPhoneInput {
    background-color: ${COLOR_FORM_FIELD_BG};
    border: none !important;
    color: #fff;
    width: 100%;
    padding-top: 2px !important;
  }

  > input,
  .styledPhoneInput::placeholder {
    color: ${COLOR_FORM_PLACEHOLDER};
    font-size: ${FONT_SIZE_DOCS_COPY};
    font-weight: 400;
  }

  > input,
  .styledPhoneInput:disabled {
    color: ${DOVE_GRAY};
    font-style: italic;
    cursor: not-allowed;
  }
`;

export default FormPhoneInput;
