import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { siteLinkDocs } from 'helper/constants/constants';
// import { smallTablet } from 'helper/constants/mediaRules';
import {
  FONT_SIZE_DOCS_LINKS_TOP_LEVEL,
  FONT_SIZE_DOCS_COPY,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization.js';

import ContactFooter from 'components/ContactFooter/ContactFooter';

const ListTitle = styled.h4`
  margin-top: 2rem;
  font-weight: 700;
  font-size: ${FONT_SIZE_DOCS_LINKS_TOP_LEVEL};
`;

const ContactPageUl = styled.ul`
  list-style: disc;
`;

const ListPoint = styled.li`
  font-size: ${FONT_SIZE_DOCS_COPY};
  line-height: 1.8em;
  width: 90%;
`;

const ContactPageList = () => (
  <>
    <ListTitle>{strings.withAccessTitle}</ListTitle>
    <ContactPageUl>
      <ListPoint>{strings.withAccessBullet1}</ListPoint>
      <ListPoint>{strings.withAccessBullet2}</ListPoint>
      <ListPoint>{strings.withAccessBullet3}</ListPoint>
    </ContactPageUl>
    <ContactFooter>
      {`${strings.lookingForTechDocs} `}
      <Link id="qa-contact-docslink" to="/documentation">{siteLinkDocs}</Link>
    </ContactFooter>
  </>
);

export default ContactPageList;
