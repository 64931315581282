import styled, { css } from 'styled-components';

import { smallTablet } from 'helper/constants/mediaRules';
import { JAVA_GREEN } from 'helper/constants/styles';

const ContactFooter = styled.p`
  font-size: 1em;
  width: 100%;
  margin-top: 3rem;

  ${smallTablet(css`
    margin-top: 5rem;
  `)}

  > a {
    color: ${JAVA_GREEN};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default ContactFooter;
