import styled, { css } from 'styled-components';
import { Field } from 'formik';

import {
  COLOR_GENERIC_TEXT,
  COLOR_FORM_FIELD_BG,
  COLOR_FORM_PLACEHOLDER,
  DOVE_GRAY,
  FONT_SIZE_DOCS_COPY,
  JAVA_GREEN,
  MONA_LISA_PINK,
} from 'helper/constants/styles';
import { smallTablet } from 'helper/constants/mediaRules';

const FormFieldElement = styled(Field)`
  background-color: ${COLOR_FORM_FIELD_BG};
  color: ${COLOR_GENERIC_TEXT};
  border: ${props => (props.error ? `${MONA_LISA_PINK} 1px solid` : 'transparent 1px solid')};
  border-radius: 10px;
  height: ${props => (props.component === 'textarea' ? '9rem' : '2.625rem')};
  margin: 0.4rem 0 1.4rem 0;
  width: 100%;
  padding-left: 1rem;
  padding-top: ${props => (props.component === 'textarea' ? '0.69rem' : '')};
  font-weight: 400;
  font-size: ${FONT_SIZE_DOCS_COPY};
  resize: vertical;

  ${smallTablet(css`
    width: 71.8%;
    margin: 0;
  `)}

  &:focus {
    border: ${props => (props.error ? MONA_LISA_PINK : JAVA_GREEN)} 1px solid;
  }

  &:disabled {
    color: ${DOVE_GRAY};
    font-style: italic;
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${COLOR_FORM_PLACEHOLDER};
    font-weight: 400;
  }
`;

export default FormFieldElement;
